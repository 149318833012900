body {
	background-color: #141414;
}

[disabled] {
	cursor: default !important;
  }
 

html {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html *{
	font-family: "Nunito", sans-serif !important;
}

code {
	font-family: "Nunito", sans-serif !important;
}

input {
	font-family: "Nunito", sans-serif !important;
}

input::placeholder {
	font-family: "Nunito", sans-serif !important;
}

html:lang(ja) * {
	font-family: "Noto Sans JP", sans-serif !important;
}

div[role="button"] > div{
	font-family: "MaterialCommunityIcons", sans-serif !important;
}

/* Remove autocomplete styling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-text-fill-color: #fff !important;
	transition: background-color 600000s 0s, color 600000s 0s !important;
}

#root > :nth-child(1) {
	height: 100vh;
	width: 100%;
	background-color: transparent !important;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

body::-webkit-scrollbar-thumb {
	background: white;
	border-radius: 5px;
	cursor: pointer;
	visibility: hidden;
}

::-webkit-scrollbar-track {
	background: transparent;
}

.floating-label-content {
	position: relative;
}

.floating-label {
	font-size: 25px;
	font-family: "Nunito", sans-serif;
	position: absolute;
	pointer-events: none;
	color: #f2f2f2;
	left: 15px;
	top: 25px;
	width: 235px;
	z-index: -1;
}

a{
	color: #F2F2F2 !important;
	text-decoration: none;
}

#root {
	cursor: default;
  }
